<template>
  <div class="container">
    <div class="buttonbc" style="position: relative;z-index: 9;">
      <el-button @click="() => { $router.go(-1); }" size="small" class="back_btn">
        <img src="@/assets/back.png" alt="">
      </el-button>
    </div>
    <el-row style="height: 100%">
      <el-col :span="12" style="height: 100%">
        <el-form :model="submit" label-width="0px" :rules="rules" ref="submit" style="height: 100%">
          <p style="display:flex; justify-content: space-between">
            <span>申请开票总额：{{ infoData.invoice_amount | unitPrice }}元</span>
            <el-button @click="submitForm" size="mini" type="primary">保存并提交</el-button>
          </p>
          <div>
            <el-tag :key="index" class="invoiceTag" :class="activeTag == index ? 'changetag' : 'unChangetag'"
              v-for="(tag, index) in dynamicTags" @click="clickTag(index)" closable @close="handleClose(tag, index)"> {{
        tag }}
            </el-tag>
            <el-button style="margin-left:5px" @click="addTage" size="mini" type="primary">添加</el-button>
          </div>
          <div style="height: 80%;overflow-y:auto;">
            <table class="invoiceTable" cellpadding="0" cellspacing="0" border="1px solid #000">
              <tr style="text-align: center">
                <th colspan="8">发票申请表</th>
              </tr>
              <tr>
                <td>申请日期</td>
                <td colspan="3">
                  {{ submit.invoiceForm[activeTag].create_time | unixToDate("yyyy-MM-dd") }}
                </td>
                <td>发票类型</td>
                <td colspan="3">
                  {{ submit.invoiceForm[activeTag].invoice_type | invoiceTypeFilter }}
                </td>
              </tr>
              <tr>
                <td><span style="color: red;">*</span>开具公司</td>
                <td colspan="6">
                  <el-form-item :prop="`invoiceForm[${activeTag}].enterprise_collecting_account_id`"
                    :rules="rules.enterprise_collecting_account_id">
                    <el-select style="width:100%" placeholder="请选择"
                      v-model="submit.invoiceForm[activeTag].enterprise_collecting_account_id" @change="changeCompany">
                      <el-option v-for="(item, index) in companyList" :key="index" :label="item.company_name"
                        :value="item.id"></el-option>
                    </el-select>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td><span style="color: red;">*</span>客户姓名</td>
                <td colspan="3">
                  <el-form-item :prop="`invoiceForm[${activeTag}].customer_name`" :rules="rules.customer_name">
                    <el-input size="small" v-model.trim="submit.invoiceForm[activeTag].customer_name" placeholder=""
                      maxlength="100" clearable></el-input>
                  </el-form-item>
                </td>
                <td>单据生成时间段</td>
                <td colspan="3">
                  {{ submit.invoiceForm[activeTag].bill_start_time | unixToDate('yyyy-MM-dd') }}~{{
        submit.invoiceForm[activeTag].bill_end_time | unixToDate('yyyy-MM-dd') }}
                </td>
              </tr>
              <tr>
                <td>收款账户</td>
                <td colspan="3">
                  <el-input size="small" v-model.trim="submit.invoiceForm[activeTag].collecting_account" placeholder=""
                    maxlength="50"></el-input>
                </td>
                <td>高于销售单金额</td>
                <td colspan="3">
                  <el-input size="small" v-model.trim="submit.invoiceForm[activeTag].exceed_bill_amount" placeholder=""
                    maxlength="50"></el-input>
                </td>
              </tr>
              <tr>
                <td>收款金额</td>
                <td colspan="3">
                  <el-input size="small" v-model.trim="submit.invoiceForm[activeTag].collecting_amount" placeholder=""
                    maxlength="50"></el-input>
                </td>
                <td>补充税费</td>
                <td colspan="3">
                  <el-input size="small" v-model.trim="submit.invoiceForm[activeTag].supplementary_taxation"
                    placeholder="" maxlength="50"></el-input>
                </td>
              </tr>
              <tr>
                <td><span style="color: red;">*</span>购货单位名称</td>
                <td colspan="6">
                  <el-form-item :prop="`invoiceForm[${activeTag}].invoice_title`" :rules="rules.invoice_title">
                    <el-input size="small" v-model.trim="submit.invoiceForm[activeTag].invoice_title" placeholder=""
                      maxlength="100"></el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td><span style="color: red;">*</span>纳税人识别号</td>
                <td colspan="6">
                  <el-form-item :prop="`invoiceForm[${activeTag}].taxpayer_on`" :rules="rules.taxpayer_on">
                    <el-input size="small" v-model.trim="submit.invoiceForm[activeTag].taxpayer_on" placeholder=""
                      maxlength="100"></el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td>
                  <span v-if="[3, 4].includes(submit.invoiceForm[activeTag].invoice_type)" style="color: red;">*</span>
                  地址
                </td>
                <td colspan="6">
                  <el-form-item v-if="[3, 4].includes(submit.invoiceForm[activeTag].invoice_type)"
                    :prop="`invoiceForm[${activeTag}].address`" :rules="rules.address">
                    <el-input size="small" v-model.trim="submit.invoiceForm[activeTag].address" placeholder=""
                      maxlength="100"></el-input>
                  </el-form-item>
                  <el-input v-else size="small" v-model.trim="submit.invoiceForm[activeTag].address" placeholder=""
                    maxlength="100"></el-input>
                </td>
              </tr>
              <tr>
                <td>
                  <span v-if="[3, 4].includes(submit.invoiceForm[activeTag].invoice_type)" style="color: red;">*</span>
                  电话
                </td>
                <td colspan="6">
                  <el-form-item v-if="[3, 4].includes(submit.invoiceForm[activeTag].invoice_type)"
                    :prop="`invoiceForm[${activeTag}].tel`" :rules="rules.tel">
                    <el-input size="small" v-model.trim="submit.invoiceForm[activeTag].tel" placeholder=""
                      maxlength="100"></el-input>
                  </el-form-item>
                  <el-input v-else size="small" v-model.trim="submit.invoiceForm[activeTag].tel" placeholder=""
                    maxlength="100"></el-input>
                </td>
              </tr>
              <tr>
                <td><span v-if="[3, 4].includes(submit.invoiceForm[activeTag].invoice_type)"
                    style="color: red;">*</span>
                  开户银行</td>
                <td colspan="6">
                  <el-form-item v-if="[3, 4].includes(submit.invoiceForm[activeTag].invoice_type)"
                    :prop="`invoiceForm[${activeTag}].bank_name`" :rules="rules.bank_name">
                    <el-input size="small" v-model.trim="submit.invoiceForm[activeTag].bank_name" placeholder=""
                      maxlength="100"></el-input>
                  </el-form-item>
                  <el-input v-else size="small" v-model.trim="submit.invoiceForm[activeTag].bank_name" placeholder=""
                    maxlength="100"></el-input>
                </td>
              </tr>
              <tr>
                <td><span v-if="[3, 4].includes(submit.invoiceForm[activeTag].invoice_type)"
                    style="color: red;">*</span>
                  开户账号</td>
                <td colspan="6">
                  <el-form-item v-if="[3, 4].includes(submit.invoiceForm[activeTag].invoice_type)"
                    :prop="`invoiceForm[${activeTag}].bank_account`" :rules="rules.bank_account">
                    <el-input size="small" v-model.trim="submit.invoiceForm[activeTag].bank_account" placeholder=""
                      maxlength="100"></el-input>
                  </el-form-item>
                  <el-input v-else size="small" v-model.trim="submit.invoiceForm[activeTag].bank_account" placeholder=""
                    maxlength="100"></el-input>
                </td>
              </tr>
              <tr class="card">
                <td style="width:130px">货物名称</td>
                <td style="width:120px">型号</td>
                <td>单位</td>
                <td style="width:70px"><span style="color: red;">*</span>单价</td>
                <td style="width:70px"><span style="color: red;">*</span>数量</td>
                <td style="width:100px">总金额</td>
                <td>操作</td>
              </tr>
              <template
                v-if="submit.invoiceForm[activeTag].customer_invoice_record_reality_goods_dos && submit.invoiceForm[activeTag].customer_invoice_record_reality_goods_dos.length">
                <tr class="card"
                  v-for="(item, index) in submit.invoiceForm[activeTag].customer_invoice_record_reality_goods_dos"
                  :key="index">
                  <td><span>{{ item.name }}</span></td>
                  <td><span>{{ item.type }}</span></td>
                  <td><span>{{ item.unit }}</span></td>
                  <td>
                    <el-form-item
                      :prop="`invoiceForm[${activeTag}].customer_invoice_record_reality_goods_dos[${index}].price`"
                      :rules="rules.price">
                      <el-input size="small" v-model.trim="item.price" placeholder=""
                        @input="statisticsPrice('price', 'totalPrice')"
                        @blur="() => { item.subtotal_price = rideitionNumber(item, item.price, item.num) }"></el-input>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item
                      :prop="`invoiceForm[${activeTag}].customer_invoice_record_reality_goods_dos[${index}].num`"
                      :rules="rules.num">
                      <el-input size="small" v-model.trim="item.num" placeholder=""
                        @input="statisticsPrice('num', 'totalNum')"
                        @blur="() => { item.subtotal_price = rideitionNumber(item, item.price, item.num) }"></el-input>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item
                      :prop="`invoiceForm[${activeTag}].customer_invoice_record_reality_goods_dos[${index}].subtotal_price`"
                      v-if="index == submit.invoiceForm[activeTag].customer_invoice_record_reality_goods_dos.length - 1"
                      :rules="rules.subtotal_price">
                      <el-input size="small" v-model.trim="item.subtotal_price" placeholder=""></el-input>
                    </el-form-item>
                    <span v-else>{{ item.subtotal_price = rideitionNumber(item, item.price, item.num) }}</span>
                  </td>
                  <td><el-button type="text" style="color:red;" icon="el-icon-delete"
                      @click="deleteCard(index)"></el-button></td>
                </tr>
              </template>
              <tr class="card">
                <td>合计</td>
                <td></td>
                <td></td>
                <td>{{ submit.invoiceForm[activeTag].totalPrice }}</td>
                <td>{{ submit.invoiceForm[activeTag].totalNum }}</td>
                <td>
                  <el-input size="small" v-model.trim="submit.invoiceForm[activeTag].totalAmount"
                    maxlength="11"></el-input>
                </td>
                <td></td>
              </tr>
              <tr class="card">
                <td>发票领取情况</td>
                <td></td>
                <td>传送方式</td>
                <td></td>
                <td>快递单号</td>
                <td></td>
                <td></td>
              </tr>
            </table>
          </div>
        </el-form>
      </el-col>
      <el-col :span="12" style="height: 90%;">
        <en-table-layout ref="enTableLayout" style="height:100%" :tableData="tableData.data"
          @selection-change="handleSelectionChange" :row-key="getRowKeys" @sort-change="handleSortChange">
          <template slot="toolbar">
            <el-form-item label="所属分类" class="col-auto">
              <el-select style="width: 110px" v-model="params.classification" placeholder="请选择" clearable>
                <el-option v-for="(item, index) in options" :key="index" :label="item" :value="item" />
              </el-select>
            </el-form-item>
            <el-form-item label="税率" class="col-auto">
              <el-select style="width: 110px" v-model="params.tax_rate" placeholder="请选择" clearable>
                <el-option label="全部" value="" />
                <el-option v-for="(item, index) in taxrateOptions" :key="index" :label="item.label"
                  :value="item.value" />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input style="width: 290px;" size="small" placeholder="请输入关键字进行搜索" v-model.trim="params.keyWord"
                clearable>
                <el-select v-model="key_word" slot="prepend" style="width: 100px;">
                  <el-option label="货物名称" value="name"></el-option>
                  <el-option label="货物编号" value="no"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="searchEvent" size="small" type="primary">搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button @click="pick" size="small" type="primary">挑选至申请表</el-button>
            </el-form-item>
          </template>

          <template slot="table-columns">
            <el-table-column type="selection" :reserve-selection="true" fixed />
            <el-table-column prop="no" show-overflow-tooltip label="货物编号" />
            <el-table-column prop="name" show-overflow-tooltip label="货物名称" width="100" />
            <el-table-column prop="classification" show-overflow-tooltip label="所属分类" width="100" />
            <el-table-column prop="tax_rate" show-overflow-tooltip label="税率" width="100">
              <template slot-scope="{row}">{{ row.tax_rate | taxRatefilter }}</template>
            </el-table-column>
            <el-table-column prop="type" show-overflow-tooltip label="型号" min-width="150" />
            <el-table-column prop="unit" show-overflow-tooltip label="单位" min-width="150" />
            <el-table-column prop="price" show-overflow-tooltip label="单价" min-width="150" sortable="custom" />
            <el-table-column prop="left_num" show-overflow-tooltip label="可开数量" min-width="150" sortable="custom" />
          </template>
          <el-pagination v-if="tableData.data" slot="pagination" @size-change="handlePageSizeChange"
            @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-size="tableData.page_size"
            :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
            :total="tableData.data_total"></el-pagination>
        </en-table-layout>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import * as API_settingCustomer from '@/api/settingCustomer';
import EnTableLayout from '@/../ui-components/TableLayout/src/main';
import { RegExp } from "@/../ui-utils";
import { Foundation } from "@/../ui-utils";
export default {
  name: 'InformationSupplementation',
  components: { EnTableLayout, },
  filters: {
    invoiceTypeFilter (type) {
      const temp = {
        1: '普通电子发票',
        2: '增值税专用发票（电子）',
        3: '普通纸质发票',
        4: '增值税专用发票（纸质）'
      }
      return temp[type]
    },
    taxRatefilter (tax_rate) {
      switch (tax_rate) {
        case '13':
          return '13%';
        case '9':
          return '9%';
        case '6':
          return '6%';
        case '0':
          return '0%';
        case '免税':
          return '免税';
      }
    },
  },
  data () {
    return {
      id: this.$route.params.id,
      companyList: [],//公司列表
      activeTag: 0,//当前选中的标签
      dynamicTags: ['发票1'],//标签
      params: {
        page_no: 1,
        page_size: 20,
        tax_rate: '',
        classification: '',
        keyWord: "",
      },

      // 列表数据
      tableData: {
        data: []
      },
      //分类
      options: [],
      //税率
      taxrateOptions: [
        { value: '13', label: '13%' },
        { value: '9', label: '9%' },
        { value: '6', label: '6%' },
        { value: '0', label: '0%' },
        { value: '免税', label: '免税' },
      ],
      key_word: 'name',
      multipleSelection: [],//选中的数据
      rules: {
        customer_name: [
          {
            required: true,
            message: "请填写客户名称",
            trigger: ["blur", "change"],
          }
        ],
        enterprise_collecting_account_id: [
          {
            required: true,
            message: "请选择开具公司名称",
            trigger: ["blur", "change"],
          },
        ],
        invoice_title: [
          {
            required: true,
            message: "请填写购货单位名称",
            trigger: ["blur", "change"],
          }
        ],
        taxpayer_on: [
          {
            required: true,
            message: "纳税人识别号",
            trigger: ["blur", "change"],
          }
        ],
        sell_name: [
          {
            required: true,
            message: "请填写纳税人识别号",
            trigger: ["blur", "change"],
          }
        ],
        address: [
          {
            required: true,
            message: "请填写地址",
            trigger: ["blur", "change"],
          }
        ],
        tel: [
          {
            required: true,
            message: "请填写电话",
            trigger: ["blur", "change"],
          }
        ],
        bank_name: [
          {
            required: true,
            message: "请填写开户行",
            trigger: ["blur", "change"],
          }
        ],
        bank_account: [
          {
            required: true,
            message: "请填写开户账号",
            trigger: ["blur", "change"],
          }
        ],
        price: [
          {
            required: true,
            message: "请填写货物单价",
            trigger: ["blur", "change"],
          },
          {
            validator: (rule, value, callback) => {

              const oldPrice = this.multipleSelection[rule.field.charAt(rule.field.length - 8)].price
              if (!RegExp.money.test(value)) {
                callback(new Error("请输入正确的金额"));
              } else if (value < Foundation.rideitionNumber(oldPrice, 0.8) || value > Foundation.rideitionNumber(oldPrice, 1.2)) {//发票进项明细表中对应单价的上下20%——也就是【原价*80%~原价*120%】的范围
                callback(new Error('单价改动范围不可超出原价的±20%'))
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
        num: [
          {
            required: true,
            message: "请填写货物数量",
            trigger: ["blur", "change"],
          }, {
            validator: (rule, value, callback) => {
              if (value > this.multipleSelection[rule.field.charAt(rule.field.length - 6)].num) {
                callback(new Error('数量不允许超出进项明细表中可开数量'))
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
        subtotal_price: [
          {
            required: true,
            message: "请填写发票总金额",
            trigger: ["blur", "change"],
          },
          {
            validator: (rule, value, callback) => {
              if (!RegExp.money.test(value)) {
                callback(new Error("请输入正确的金额"));
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ]
      },
      submit: {
        invoiceForm: [
          {
            customer_invoice_record_reality_goods_dos: [],//挑选的货物清单
            record_id: this.id,
            create_time: '',//申请日期
            invoice_type: '',//发票类型
            company_name: '',//开具公司
            enterprise_collecting_account_id: '',
            customer_id: '',
            customer_name: '',//客户姓名
            bill_start_time: '',//单据生成时间段开始
            bill_end_time: '',//单据生成时间段结束
            collecting_account: '',//收款账户
            exceed_bill_amount: '',//高于销售单金额
            collecting_amount: '',//收款金额
            supplementary_taxation: '',//补充税费
            invoice_title: '',//购货单位名称
            taxpayer_on: '',//纳税人识别号
            address: '',//地址
            tel: '',//电话
            bank_name: '',//开户银行
            bank_account: '',//开户账号
            totalPrice: 0,//合计单价
            totalNum: 0,//合计数量
            totalAmount: 0,//合计总金额
          }
        ],
      },
      infoData: {},//详情内容
    }
  },
  mounted () {
    this.getAllCompany();
    this.getDetail();
  },
  methods: {
    /**输入框金额或数量改变时变更合计 */
    statisticsPrice (name, total) {
      let data = 0
      this.submit.invoiceForm[this.activeTag].customer_invoice_record_reality_goods_dos.forEach(item1 => {
        // data += Number(item1[name])
        data = Foundation.addition(item1[name], data)
      })
      this.submit.invoiceForm[this.activeTag][total] = data
    },
    /** 获取详情 */
    getDetail () {
      API_settingCustomer.getInvoiceInfo(this.id).then(res => {
        this.infoData = res;
        this.submit.invoiceForm = [
          {
            customer_invoice_record_reality_goods_dos: [],//挑选的货物清单
            record_id: this.id,
            create_time: this.infoData.create_time,//申请日期
            invoice_type: this.infoData.invoice_type,//发票类型
            company_name: '',//开具公司
            enterprise_collecting_account_id: '',
            customer_id: this.infoData.customer_id,
            customer_name: this.infoData.customer_name,//客户姓名
            bill_start_time: this.infoData.bill_start_time,//单据生成时间段开始
            bill_end_time: this.infoData.bill_end_time,//单据生成时间段结束
            collecting_account: '',//收款账户
            exceed_bill_amount: '',//高于销售单金额
            collecting_amount: '',//收款金额
            supplementary_taxation: '',//补充税费
            invoice_title: this.infoData.invoice_title,//购货单位名称
            taxpayer_on: this.infoData.tax_no,//纳税人识别号
            address: [1, 2].includes(this.infoData.invoice_type) ? this.infoData.reg_address : res.receive_province ? res.receive_province + res.receive_city + res.receive_county + (res.receive_town || '') + res.receive_address : '',//地址
            tel: this.infoData.reg_tel,//电话
            bank_name: this.infoData.bank_name,//开户银行
            bank_account: this.infoData.bank_account,//开户账号
            totalPrice: 0,//合计单价
            totalNum: 0,//合计数量
            totalAmount: this.infoData.invoice_amount,//合计总金额
          }
        ]
        console.log(this.submit.invoiceForm);
      });
    },
    handleClose (tag, index) {//删除标签
      if (this.dynamicTags.length > 1) {
        this.activeTag = 0
        this.dynamicTags = this.dynamicTags.filter((item, j) => index !== j);
        this.submit.invoiceForm = this.submit.invoiceForm.filter((item, j) => index !== j);
        this.dynamicTags = this.dynamicTags.map((item, index) => {
          item = `发票${index + 1}`
          return item
        })
      }
    },
    clickTag (index) {//切换标签
      this.activeTag = index
      this.tableData = []
      this.params.es_enterprise_collecting_account_id = this.submit.invoiceForm[this.activeTag].enterprise_collecting_account_id;
      this.$refs.enTableLayout.$refs.table.clearSelection();
      if (this.params.es_enterprise_collecting_account_id) {
        this.GET_GoodsAuditList();
      }
    },
    addTage () {
      let allMoney = 0;
      this.submit.invoiceForm.map(res => allMoney += Number(res.totalAmount))
      if (allMoney >= this.infoData.invoice_amount) return this.$message.error('暂无可新增发票金额值，无法新增发票页签')
      this.dynamicTags.push(`发票${this.dynamicTags.length + 1}`)
      const obj = {
        customer_invoice_record_reality_goods_dos: [],//挑选的货物清单
        record_id: this.id,
        create_time: this.infoData.create_time,//申请日期
        invoice_type: this.infoData.invoice_type,//发票类型
        company_name: '',//开具公司
        enterprise_collecting_account_id: '',
        customer_id: this.infoData.customer_id,
        customer_name: this.infoData.customer_name,//客户姓名
        bill_start_time: this.infoData.bill_start_time,//单据生成时间段开始
        bill_end_time: this.infoData.bill_end_time,//单据生成时间段结束
        collecting_account: '',//收款账户
        exceed_bill_amount: '',//高于销售单金额
        collecting_amount: '',//收款金额
        supplementary_taxation: '',//补充税费
        invoice_title: this.infoData.invoice_title,//购货单位名称
        taxpayer_on: this.infoData.tax_no,//纳税人识别号
        address: [1, 2].includes(this.infoData.invoice_type) ? this.infoData.reg_address : this.infoData.receive_province + this.infoData.receive_city + this.infoData.receive_county + (this.infoData.receive_town || '') + this.infoData.receive_address,//地址
        tel: this.infoData.reg_tel,//电话
        bank_name: this.infoData.bank_name,//开户银行
        bank_account: this.infoData.bank_account,//开户账号
        totalPrice: 0,//合计单价
        totalNum: 0,//合计数量
        totalAmount: Foundation.subtraction(this.infoData.invoice_amount, allMoney),//合计总金额
      }
      this.submit.invoiceForm.push(obj)
    },
    /**排序事件 */
    handleSortChange ({
      prop,
      order
    }) {
      if (order) {
        this.params.sort = `${prop}_${order.includes("asc") ? "asc" : "desc"}`;
      } else {
        this.params.sort = "";
      }
      this.GET_GoodsAuditList();
    },
    /** 搜索事件触发 */
    searchEvent (data) {
      this.params.page_no = 1;
      this.GET_GoodsAuditList();
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_GoodsAuditList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_GoodsAuditList();
    },

    /** 获取分类数据 */
    async GET_classification () {
      this.options = await API_settingCustomer.getCustomerAccountIncomeClassification(this.params.es_enterprise_collecting_account_id)
    },

    /** 获取列表数据 */
    GET_GoodsAuditList () {
      this.loading = true;
      let params = {
        ...this.params
      };
      params[this.key_word] = params.keyWord
      delete params.keyWord
      if (!params.es_enterprise_collecting_account_id) {
        params.es_enterprise_collecting_account_id = 999
      }
      API_settingCustomer.getCustomerAccountIncome(params).then(response => {
        response.data.forEach(item => {
          item.subtotal_price = Foundation.rideitionNumber(item.price || 0, item.num || 0)
          item.goods_id = item.id
          item.num = item.left_num
          item.goods_name = item.name
          item.goods_no = item.no
        })

        this.tableData = response;
        //如果发票表单选中的数据不为空 就回显复选框
        if (this.submit.invoiceForm[this.activeTag].customer_invoice_record_reality_goods_dos.length > 0) {
          this.multipleSelection = this.submit.invoiceForm[this.activeTag].customer_invoice_record_reality_goods_dos;
        } else {
          this.multipleSelection = []
        }
        this.multipleSelection.forEach(item => {
          this.$refs.enTableLayout.$refs.table.toggleRowSelection(item, true);
        })

      }).catch(() => (this.loading = false));
    },
    //选中数据
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    //挑选至申请表
    pick () {
      if (this.multipleSelection) {
        this.submit.invoiceForm[this.activeTag].customer_invoice_record_reality_goods_dos = JSON.parse(JSON.stringify(this.multipleSelection));
        this.submit.invoiceForm[this.activeTag].customer_invoice_record_reality_goods_dos.forEach(item => {
          item.num = 1
          item.subtotal_price = Foundation.rideitionNumber(item.price, 1)
        });
        this.totalFomater()
      }
    },
    getRowKeys (row) {
      return row.id;
    },
    //删除选中的货物右侧同步取消勾选
    deleteCard (index) {
      const rows = this.tableData.data.find(item => item.no == this.submit.invoiceForm[this.activeTag].customer_invoice_record_reality_goods_dos[index].goods_no)
      this.$refs.enTableLayout.$refs.table.toggleRowSelection(rows, false);
      this.submit.invoiceForm[this.activeTag].customer_invoice_record_reality_goods_dos = this.submit.invoiceForm[this.activeTag].customer_invoice_record_reality_goods_dos.filter((item, k) => k !== index)
      this.totalFomater()
    },
    totalFomater () {
      let totalPrice = 0
      let totalNum = 0
      this.submit.invoiceForm[this.activeTag].customer_invoice_record_reality_goods_dos.forEach(item1 => {
        totalPrice = Foundation.addition(item1.price, totalPrice)
        totalNum = Foundation.addition(item1.num, totalNum)
      })
      this.submit.invoiceForm[this.activeTag].totalPrice = totalPrice
      this.submit.invoiceForm[this.activeTag].totalNum = totalNum
    },
    /**获取所有公司列表 */
    async getAllCompany () {
      const res = await API_settingCustomer.getAllCompanyByEnterpriseId(this.$store.getters.shopInfo.shop_id)
      this.companyList = res;
    },
    changeCompany (id) {//公司选择事件
      this.params.es_enterprise_collecting_account_id = id;
      const { company_name } = this.companyList.find(res => res.id == id)
      this.submit.invoiceForm[this.activeTag].company_name = company_name;

      this.submit.invoiceForm[this.activeTag].customer_invoice_record_reality_goods_dos = [];
      this.multipleSelection = [];
      this.$refs.enTableLayout.$refs.table.clearSelection();
      //查询表格数据
      this.searchEvent()
      //查询所属分类
      this.GET_classification()
    },

    /**
     * 处理高精度乘法
     * @param {*} time 
     * @param {*} label 
     * @returns 
     */
    rideitionNumber (item, arg1, arg2) {  
      if (arg1.toString().split('.').length-1>1&&!RegExp.money.test(arg1)) {
        arg1 = 0
        item.price = ''
      }
      if (!RegExp.integer.test(arg2)) {
        arg2 = 0
        item.num = ''
      }
      var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
      try {
        m += s1.split(".")[1].length;
      }
      catch (e) {
      }
      try {
        m += s2.split(".")[1].length;
      }
      catch (e) {
      }
      return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
    },
    async submitForm () {
      // 校验表单
      this.$refs.submit.validate((validate) => {
        if (validate) {
          if (this.validateAllMoney() && this.validateTotalInvoiceAmount()) {
            API_settingCustomer.saveRealityInvoice(this.submit.invoiceForm).then(res => {
              this.$router.go(-1)
            })
          }
        } else {
          // this.$message.error('请填写完整信息!')
          return false
        }
      })


    },
    /**验证所有货物的总金额汇总，是否等于合计总金额输入框中内容 */
    validateAllMoney () {
      let arr = true
      this.submit.invoiceForm.forEach(res => {
        let monery = 0;
        res.customer_invoice_record_reality_goods_dos.forEach(item => {
          monery = Foundation.addition(item.subtotal_price, monery)
        })
        if (Number(res.totalAmount) !== monery) {
          this.$message.error('所选货物总金额与要申请的发票金额不等')
          arr = false
          return false
        }
      })
      return arr
    },
    /**校验所有发票页签的合计总金额汇总值是否等于发票申请总金额 */
    validateTotalInvoiceAmount () {
      let monery = 0;
      this.submit.invoiceForm.forEach(item => {
        monery = Foundation.addition(item.totalAmount, monery)
      })
      if (Number(this.infoData.invoice_amount) !== monery) {
        this.$message.error('发票页签合计总金额值不等于发票申请金额，无法保存')
        return false
      } else {
        return true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 88vh;
  background: #ffff;
  padding-top: 20px;
  padding-left: 10px;

  .unChangetag {
    background: #eee;
    color: #999;
  }

  .changetag {
    background: #409EFF;
    color: #fff;
  }

  .invoiceTag:hover {
    cursor: pointer;
  }

  .invoiceTable {
    height: 200px;
    overflow-y: auto;

    tr {
      height: 30px;
    }

    td {
      text-align: center;
      padding: 10px 10px;
    }
  }

  /deep/ .el-table--border {
    height: 400px;
  }
}

.el-form-item {
  margin-bottom: 8px;
}
</style>